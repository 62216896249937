<template>
  <v-container>
    <v-row>
      <v-col class="d-flex justify-start" style="font-size: 20px;">
        GoDial Analytics
      </v-col>

      <v-col class="d-flex justify-end">
        <v-btn text color="#00CF46" class="ml-0" @click="backtoGopages()">
          &lt; Back to GoPages
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <!-- <v-col cols="3">
        <v-card class="pa-4 text-center" style="min-height: 160px;">
          <h3>2862</h3>
          <p>Total Clicks</p>
        </v-card>
      </v-col> -->
      <v-col cols="6">
        <v-card class="pa-4 text-center">
          <h3>{{ totalLeads }}</h3>
          <p>Total Leads</p>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card class="pa-4 text-center">
          <h3>{{ callCount }}/{{ totalLeads }}</h3>
          <p>Called</p>
        </v-card>
      </v-col>
      <!-- <v-col cols="3">
        <v-card class="pa-4 text-center" style="min-height: 160px;">
          <v-progress-circular
            :value="78.9"
            color="#00CF46"
            size="80"
            width="7"
            class="mb-2"
          >
            78.9%
          </v-progress-circular>
          <p>Conversion</p>
        </v-card>
      </v-col> -->
    </v-row>
    <v-row>
      <v-col>
        <v-row class="d-flex align-center">
          <v-col cols="2">
            <v-select
              v-model="entries"
              :items="['500', '1000', '5000', '10000', '50000', '100000']"
              label="Show"
              dense
              hide-details
            ></v-select>
          </v-col>
          <v-col cols="6">
            <!-- <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              dense
              hide-details
            ></v-text-field> -->
          </v-col>

          <v-col cols="4" class="d-flex justify-end">
            <!-- <v-btn outlined color="#00CF46" class="mr-2">
              Export
            </v-btn> -->
          </v-col>

          <!-- <v-col cols="4" class="d-flex justify-center">
            <a @click="openAssignMember()">
              <v-icon style="font-size: 50px;color: #37cf37;"
                >mdi-plus-circle</v-icon
              >

              Assign Member
            </a>
          </v-col> -->
        </v-row>

        <div style="padding-top: 20px;">
          <v-simple-table>
            <thead>
              <tr>
                <th class="text-left">Name</th>
                <th class="text-left">Phone</th>
                <th class="text-left">Email</th>
                <th class="text-left">Disposition</th>
                <th class="text-left">Source</th>
                <th class="text-left">Created On</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in items"
                :key="item.name"
                :class="{ 'green-row': index % 2 === 1 }"
              >
                <td>{{ item.name }}</td>
                <td>{{ item.Phone }}</td>
                <td>{{ item.email }}</td>
                <td>{{ item.dispo }}</td>
                <td>{{ item.source }}</td>
                <td>{{ $moment(item.createdOn).format("lll") }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment-timezone";

export default {
  props: ["item"],
  data() {
    return {
      entries: "500",
      search: "",
      callCount: "0",
      totalLeads: "0",
      items: [
        {
          name: "Matt Dickerson",
          email: "test@gmail.com",
          contact: "+919861078837",
          source: "google.com",
          date: "14/07/2024",
        },
        {
          name: "Wiktoria",
          email: "test@gmail.com",
          contact: "+919861078837",
          source: "facebook.com",
          date: "14/07/2024",
        },
        {
          name: "Trixie Byrd",
          email: "test@gmail.com",
          contact: "+919861078837",
          source: "reddit.com",
          date: "14/07/2024",
        },
        {
          name: "Brad Mason",
          email: "test@gmail.com",
          contact: "+919861078837",
          source: "google.com",
          date: "14/07/2024",
        },
      ],
    };
  },

  computed: {
    ...mapGetters(["ENDPOINT", "user", "fieldList", "teams", "companyDetails"]),

    dispoList() {
      return this.$store.getters.dispoList;
    },
    noReports() {
      return require("../assets/noReports.png");
    },
    selectedTeam: {
      get(v) {
        return this.$store.getters.selectedTeam;
      },
      set(v) {
        this.$store.commit("setSelectedTeam", v);
      },
    },
    calcDispo() {
      return _.without(
        _.map(this.dispos, (d) => {
          if (
            d.title == "SMS" ||
            d.title == "LINK" ||
            d.title == "EMAIL" ||
            d.title == "APP" ||
            d.title == "NEW"
          ) {
            //do not pass these values
          } else {
            return d;
          }
        }),
        undefined
      );
    },
    paginate() {
      return this.$store.getters.paginate;
    },
  },
  watch: {
    entries() {
      this.fetchTableData(); // Refetch table data when entries change
    },
  },
  async mounted() {
    await this.fetchCounts();
    await this.fetchTableData();
    // console.log("this....................123", this.item); // Check the passed item
  },
  methods: {
    backtoGopages() {
      this.$router.replace({ path: "/gopages" });
    },

    fetchTableData() {
      var dispos = _.map(this.dispoList, (d) => {
        return d.title;
      });

      const dataPayload = {
        queryCase: "data",
        predicates: [],
        matchType: "",
        lists: [this.item.listsId], // Use listId from props
        dispos: dispos,
        sortField: "",
        sortOrder: "asc",
        limit: parseInt(this.entries), // Convert entries to an integer
        skip: 0,
      };
      this.$http
        .post(`${this.ENDPOINT}/contacts/byFilterV4`, dataPayload)
        .then((response) => {
          this.items = response.body;
        })
        .catch((error) => {
          console.error("Error fetching table data:", error);
        });
    },
    fetchCounts() {
      var dispos = _.map(this.dispoList, (d) => {
        return d.title;
      });

      const payload = {
        queryCase: "count",
        predicates: [],
        matchType: "",
        lists: [this.item.listsId], // Use listId from props
        dispos: dispos,
      };
      this.$http
        .post(`${this.ENDPOINT}/contacts/byFilterV4`, payload)
        .then((response) => {
          this.totalLeads = response.body.count;
          this.callCount = response.body.cCount;
        })
        .catch((error) => {
          console.error("Error fetching counts:", error);
        });
    },
    openAssignMember() {
      console.log("assign member");
    },
  },
};
</script>

<style scoped>
.v-card-title {
  font-size: 1.25rem;
  font-weight: bold;
}
.v-simple-table td {
  vertical-align: middle;
}
.green-row {
  background-color: #cffede; /* Light green color */
}
</style>
